.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-top: 25px;
}

.Header-logo {
  width: 100px;
  height: 50px;
  object-fit: scale-down;
  /* float: left;
  margin-left: 50px;
  display: inline-flex; */
}

.Header-text {
  margin: 25px 25px 25px 25px;
}

.App-header {
  background-color: white;
  min-height: 5vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-content {
  background-color: #282c34;
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: black;
  min-height: 15vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: gray;
}

.App-link {
  width: 250px;
  height: 100px;
  object-fit: scale-down;
}

.Footer-link {
  color: gray;
  text-decoration: none;
  margin-bottom: 10px;
}

.PP-main {
  margin: 25px 25px 25px 25px;
}